import Image from "next/image";
import Link from "next/link";

import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import dynamic from "next/dynamic";
const WrappedLottiePlayer = dynamic(
	() => import("@/components/common/WrappedLottiePlayer"),
	{
		ssr: false,
	}
);

const HighlightWorkbooks = () => {
	return (
		<section
			id="courseWorkbooks"
			aria-labelledby="course-workbooks-title"
			className="relative pt-10 bg-[#F7FAFC]  mx-auto text-black overflow-hidden"
		>
			<div className="flex flex-col gap-x-4 md:flex-row max-w-7xl  mx-auto">
				<div className="w-full  mb-12 md:mb-0 px-4 pt-4 pb-6 md:pb-14 md:w-1/2 order-1 md:order-2">
					<div className="flex w-full justify-center md:justify-start">
						<div className="w-16 mb-4">
							<WrappedLottiePlayer
								autoplay={true}
								hover={true}
								loop={true}
								src={`/img/home/icons/book.json`}
							/>
						</div>
					</div>
					<h2
						id="course-workbooks-title"
						className="font-heading font-bold text-4xl mb-4 "
					>
						Take your learning even further with our course workbooks
					</h2>
					<p className="text-lg mb-8 ">
						Our workbooks are specially crafted to complement our ASL courses.
						They&apos;re packed with carefully designed exercises that focus on
						key language aspects, making it a breeze for you to grasp the
						subtleties of ASL. So, if you&apos;re aiming to master ASL, these
						workbooks are definitely your go-to resource!
					</p>
					<Link
						href="/books"
						className="hover:underline hover:underline-offset-2 uppercase font-semibold "
					>
						<p>
							View Books <ArrowLongRightIcon className="w-5 h-5 inline-block" />
						</p>
					</Link>
				</div>

				<div className="overflow-hidden flex   md:relative  align-bottom w-full md:w-1/2 order-2 md:order-1">
					<Image
						src="/img/home/workbook-interiors-horizontal.png"
						alt="ASL course workbooks"
						width={884}
						height={500}
						className="hidden xl:inline xl:absolute bottom-0 left-0"
					/>
					<Image
						src="/img/home/workbook-interiors-horizontal-md.png"
						alt="ASL course workbooks"
						width={676}
						height={517}
						className="hidden md:inline md:absolute bottom-0 left-0 xl:hidden"
					/>
					<Image
						src="/img/home/workbook-interiors-horizontal-md.png"
						alt="ASL course workbooks"
						width={676}
						height={517}
						className="md:hidden "
					/>
				</div>
			</div>
		</section>
	);
};

export default HighlightWorkbooks;
